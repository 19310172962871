
import Vue from "vue";
import MentoringPlanTimeline from "@/components/MentoringPlan/MentoringPlanTimeline.vue";
import MentoringSessionChat from "@/components/Session/MentoringSessionChat.vue";
import MentorcastCertificates from "@/components/Mentorcast/MentorcastCertificates.vue";
import ProgramContentTab from "@/components/MentoringPlan/ProgramContentTab.vue";

export default Vue.extend({
  name: "MentoringPlanTabs",
  components: {
    MentoringSessionChat,
    MentoringPlanTimeline,
    MentorcastCertificates,
    ProgramContentTab
  },
  props: {
    mentoringPlan: {
      type: Object
    }
  },
  data() {
    return {};
  }
});
