
import Vue from "vue";
import moment from "moment";
import { InformationData, Session } from "@/types/interfaces";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentoringPlan",
  props: {
    mentoringPlan: {
      type: Object
    }
  },
  data() {
    return {
      // Table data
      infoData: [
        {
          name: "start_date",
          label: (this as any).$t("mentroingprogram.infoDataStarted"),
          icon: "calendar",
          value: null
        },
        {
          name: "price",
          label: (this as any).$t("mentroingprogram.infoDataPrice"),
          icon: "price",
          value: null
        },
        {
          name: "no_of_sessions",
          label: (this as any).$t("mentroingprogram.infoSessions"),
          icon: "tea-icon",
          value: null
        },
        {
          name: "study_items",
          label: (this as any).$t("mentroingprogram.infoDataStudyItems"),
          icon: "books",
          value: null
        },
        {
          name: "duration",
          label: (this as any).$t("mentroingprogram.infoDataDuration"),
          icon: "time-circle",
          value: null
        }
      ] as InformationData[],
      socialLinks: [
        (this as any).$t("mentroingprogram.socialLinksTwitter"),
        (this as any).$t("mentroingprogram.socialLinksFacebook"),
        (this as any).$t("mentroingprogram.socialLinksInstagram")
      ],
      statuses: {
        pending: {
          label: (this as any).$t("mentroingprogram.statusesPending"),
          color: "#FB9391",
          icon: "mentoring-plan-pending"
        },
        cancelled: {
          label: (this as any).$t("mentroingprogram.statusesCancelled"),
          color: "#FB9391",
          icon: "mentoring-plan-pending"
        },
        ongoing: {
          label: (this as any).$t("mentroingprogram.statusesOngoing"),
          color: "#3981CB",
          icon: "mentoring-plan"
        },
        completed: {
          label: (this as any).$t("mentroingprogram.statusesCompleted"),
          color: "#20B737",
          icon: "mentoring-plan-completed"
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency",
      profile: "profile/getProfile"
    })
  },
  created(): void {
    // Find mentoring plan information by name
    for (const key of Object.keys(this.mentoringPlan)) {
      const index = this.infoData.findIndex(i => i.name === key);
      if (index > -1) {
        if (key === "start_date") {
          //@ts-ignore
          this.infoData[index].value = moment(this.mentoringPlan[key]).format(
            "Do MMMM yyyy"
          );
          continue;
        } else if (key === "price") {
          this.infoData[index].value = `${this.platformCurrency} ${
            this.mentoringPlan[key as keyof Session]
          }`;
          continue;
        }
        this.infoData[index].value = this.mentoringPlan[key as keyof Session];
      }
    }

    const sessionIndex = this.infoData.findIndex(
      i => i.name === "no_of_sessions"
    );
    const studyItemIndex = this.infoData.findIndex(
      i => i.name === "study_items"
    );
    const durationIndex = this.infoData.findIndex(i => i.name === "duration");

    this.infoData[sessionIndex].value = this.noOfSessionMileStone();
    this.infoData[studyItemIndex].value = this.noOfStudyMileStone();
    this.infoData[
      durationIndex
    ].value = `${this.mentoringPlan.duration} ${this.mentoringPlan.duration_type}`;
  },
  methods: {
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    noOfSessionMileStone(): number {
      return this.mentoringPlan.milestones.filter(
        (milestone: any) => milestone.type === "session"
      ).length;
    },
    noOfStudyMileStone(): number {
      return this.mentoringPlan.milestones.filter((milestone: any) =>
        ["library_item", "book", "document", "multimedia"].includes(
          milestone.type
        )
      ).length;
    }
  }
});
