<template>
  <div>
    <b-container class="px-5 px-sm-0 pt-7 pt-sm-9 pb-4 pb-sm-6">
      <b-row class="" no-gutters>
        <MentoringPlan
          v-if="!loading && mentoringPlan"
          :mentoringPlan="mentoringPlan"
        />
      </b-row>
      <MentoringPlanTabs
        v-if="!loading && mentoringPlan"
        :mentoring-plan="mentoringPlan"
        class="mt-7"
        @refresh="getProgram"
      />
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import MentoringPlan from "../../components/MentoringPlan/MentoringPlan";
import { mapGetters } from "vuex";
import MentoringPlanTabs from "../../components/MentoringPlan/MentoringPlanTabs";
import Socket from "@/services/Socket";

export default Vue.extend({
  name: "MentoringPlanPage",
  components: {
    MentoringPlanTabs,
    MentoringPlan
  },
  data() {
    return {
      loading: true,
      mentoringPlan: null
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  beforeMount() {
    Socket().on("notification", data => {
      if (
        data.type &&
        data.type === "App\\Notifications\\Mentor\\MentoringProgramAccepted"
      ) {
        this.getProgram();
      }
    });
  },
  beforeDestroy() {
    Socket().off("notification");
  },
  mounted() {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
    this.getProgram();
  },
  methods: {
    getProgram() {
      this.loading = true;
      this.mentoringPlan = null;
      this.$store
        .dispatch("program/GET_PROGRAM", this.$route.params.id)
        .then(data => {
          this.mentoringPlan = data;
          if (
            this.profile.id !== this.mentoringPlan.mentee_id &&
            this.profile.id !== this.mentoringPlan.mentor_id
          ) {
            this.loading = true;
            this.$router.push("/");
          }
          this.loading = false;
        });
    }
  }
});
</script>

<style scoped></style>
