
import Vue from "vue";
import ProgramContents from "./ProgramContents.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ProgramContentTab",
  components: {
    ProgramContents
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  }
});
