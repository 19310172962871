
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentoringPlanOptionsDropdown",
  props: {},
  data() {
    return {
      options: [
        {
          label: (this as any).$t(
            "mentroingprogram.mentoringProgramAddMilestoneLabel"
          ),
          icon: "add-milestone",
          action: () => this.$bvModal.show("add-milestone"),
          userType: "mentor"
        },
        {
          label: (this as any).$t(
            "mentroingprogram.mentoringProgramConcludeProgramLabel"
          ),
          icon: "completed-status",
          action: () => this.$bvModal.show("conclude-program"),
          userType: "mentor"
        },
        // {
        //   label: "Cancel and Refund",
        //   icon: "cancel-status",
        //   action: () => null,
        //   userType: "mentor"
        // },
        {
          label: (this as any).$t(
            "mentroingprogram.mentoringProgramRequestRefundLabel"
          ),
          icon: "refund",
          action: () => this.$bvModal.show("request-refund"),
          userType: "mentee"
        },
        {
          label: (this as any).$t(
            "mentroingprogram.mentoringProgramReportMentorLabel"
          ),
          icon: "report-mentor",
          action: () => this.$bvModal.show("report-user"),
          userType: "mentee"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  methods: {}
});
