<template>
  <div>
    <MentoringPlanOptionsDropdown v-if="!isConcluded" />
    <b-container
      class="bg-light-blue box-shadow border-radius-10 px-5 py-5 mt-4"
      fluid=""
    >
      <template v-if="draggableList.length">
        <draggable
          :list="draggableList"
          handle=".milestone-drag"
          :options="{ animation: 300 }"
          @end="moveItem"
        >
          <transition-group>
            <MentoringPlanMilestone
              v-for="milestone in draggableList"
              :key="milestone.id"
              :milestone="milestone"
              :milestone-types="milestoneTypes"
              :milestone-statuses="milestoneStatuses"
              :is-concluded="isConcluded"
              @view-milestone="viewMilestone"
              @update-milestone="updateMilestone"
              @edit-milestone="editMilestone"
            />
          </transition-group>
        </draggable>
      </template>
      <p v-else class="text-center">
        {{ $t("mentroingprogram.noRecordLabel") }}
      </p>
    </b-container>
    <RequestRefundModal @refresh="$emit('refresh')" />
    <ReportUserModal :user="mentor" />
    <ConcludeProgramModal
      :mentor="mentor"
      :mentee="mentee"
      @refresh="$emit('refresh')"
    />
    <CannotConcludeProgramModal />
    <ViewMilestoneModal
      v-if="activeMilestone"
      :milestone="activeMilestone"
      :milestone-types="milestoneTypes"
    />
    <UpdateMilestoneModal
      v-if="activeMilestone"
      :milestone-id="activeMilestone.id"
      :active-status="activeMilestone.status"
      :milestone-statuses="milestoneStatuses"
      :is-concluded="isConcluded"
      @refresh="$emit('refresh')"
    />
    <AddMilestoneModal
      :milestone="activeMilestone"
      :milestone-types="milestoneTypes"
      :is-edit-mode="activeMilestone && activeMilestone.id > 0"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import MentoringPlanMilestone from "./MentoringPlanMilestone";
import Vue from "vue";
import MentoringPlanOptionsDropdown from "@/components/MentoringPlan/MentoringPlanOptionsDropdown";
import RequestRefundModal from "@/components/Modals/RequestRefundModal";
import ViewMilestoneModal from "@/components/Modals/ViewMilestoneModal";
import UpdateMilestoneModal from "@/components/Modals/UpdateMilestoneModal";
import ConcludeProgramModal from "@/components/Modals/ConcludeProgramModal";
import CannotConcludeProgramModal from "@/components/Modals/CannotConcludeProgramModal";
import ReportUserModal from "@/components/Modals/ReportUserModal";
import AddMilestoneModal from "@/components/Modals/AddMilestoneModal";
import draggable from "vuedraggable";
import Api from "@/services/Api";

export default Vue.extend({
  name: "MentoringPlanTimeline",
  components: {
    AddMilestoneModal,
    CannotConcludeProgramModal,
    ConcludeProgramModal,
    UpdateMilestoneModal,
    ViewMilestoneModal,
    RequestRefundModal,
    MentoringPlanOptionsDropdown,
    MentoringPlanMilestone,
    ReportUserModal,
    draggable
  },
  props: {
    timeline: {
      type: Array
    },
    mentor: {
      type: Object
    },
    mentee: {
      type: Object
    },
    isConcluded: {
      type: Boolean
    }
  },
  data() {
    return {
      activeMilestone: null,
      milestoneTypes: {
        library_item: {
          label: this.$t("mentroingprogram.milestoneTypesLibraryItemLabel"),
          icon: "books",
          id: "library_item"
        },
        session: {
          label: this.$t("mentroingprogram.milestoneTypesSessionLabel"),
          icon: "tea-table",
          id: "session"
        },
        multimedia: {
          label: this.$t("mentroingprogram.milestoneTypesMultimediaLabel"),
          icon: "multimedia",
          id: "multimedia"
        },
        book: {
          label: this.$t("mentroingprogram.milestoneTypesBookLabel"),
          icon: "book",
          id: "book"
        },
        document: {
          label: this.$t("mentroingprogram.milestoneTypesDocumentLabel"),
          icon: "document-main",
          id: "document"
        },
        mentorcast: {
          label: this.$t("mentroingprogram.milestoneTypesMentorcastLabel"),
          icon: "scene",
          id: "mentorcast"
        }
      },
      milestoneStatuses: {
        pending: {
          label: this.$t("mentroingprogram.milestoneStatusesPending"),
          icon: "pending-status",
          color: "#3E85D0"
        },
        not_met: {
          label: this.$t("mentroingprogram.milestoneStatusesNotMet"),
          icon: "failed-status",
          color: "#AD4600"
        },
        skipped: {
          label: this.$t("mentroingprogram.milestoneStatusesSkipped"),
          icon: "skipped-status",
          color: "#80ACFE"
        },
        completed: {
          label: this.$t("mentroingprogram.milestoneStatusesCompleted"),
          icon: "completed-status",
          color: "#34C54B"
        }
      },
      draggableList: this.timeline
    };
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", () => {
      this.activeMilestone = null;
    });
  },
  methods: {
    setMilestone(id) {
      this.activeMilestone = this.timeline.find(item => item.id === id);
    },
    viewMilestone(id) {
      this.setMilestone(id);
      this.$nextTick(() => {
        this.$bvModal.show("view-milestone");
      });
    },
    updateMilestone(id) {
      this.setMilestone(id);
      this.$nextTick(() => {
        this.$bvModal.show("update-milestone");
      });
    },
    editMilestone(id) {
      this.setMilestone(id);
      this.$nextTick(() => {
        this.$bvModal.show("add-milestone");
      });
    },
    async moveItem(item) {
      try {
        const { oldIndex, newIndex } = item;
        const newItem = this.draggableList.at(newIndex);
        const oldItem = this.draggableList.at(oldIndex);

        if (!oldItem && !newItem) return false;

        const data = [
          {
            id: newItem.id,
            index: newIndex + 1
          },
          {
            id: oldItem.id,
            index: oldIndex + 1
          }
        ];

        await Api().post(`program/${this.$route.params.id}/milestone/sort`, {
          data
        });

        this.$emit("refresh");
      } catch (error) {
        console.error(error);
      }
    }
  }
});
</script>
