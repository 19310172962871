var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-container',{staticClass:"bg-light-blue box-shadow border-radius-10 text-center overflow-hidden px-0",attrs:{"fluid":""}},[_c('b-row',{staticClass:"pt-4",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"pl-5 pl-sm-6 pr-2 pr-sm-6 pr-md-0 pr-lg-9",attrs:{"cols":"6","md":"3","order":"2","order-md":"1"}},[_c('b-row',{staticClass:"flex-column h-100",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-inline-block position-relative rounded-pill border border-2 border-color-lightgreen"},[_c('router-link',{attrs:{"to":_vm.profileLink(_vm.mentoringPlan.mentor)}},[_c('b-avatar',{staticClass:"border-white main-avatar",attrs:{"src":_vm.mentoringPlan.mentor && _vm.mentoringPlan.mentor.profile_link,"size":"7.15rem"}}),_c('span',{staticClass:"active-indicator"})],1)],1)]),_c('b-col',{staticClass:"mt-3"},[_c('b-row',{staticClass:"bg-white border-radius-7 py-1 px-1 h-100",attrs:{"align-h":"center","no-gutters":""}},[_c('b-col',{staticClass:"font-size-18 font-weight-semi-bold",attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":_vm.profileLink(_vm.mentoringPlan.mentor)}},[_c('p',{staticClass:"text-color-2 mentoring-session-name-font"},[_vm._v(" "+_vm._s(_vm.mentoringPlan.mentor.name)+" "),_c('b-img',{attrs:{"src":require('@/assets/icons/mentor.png'),"height":"18"}})],1)])],1),_c('b-col',{staticClass:"ml-1",attrs:{"cols":"auto"}}),_c('b-col',{staticClass:"font-size-12",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.mentoringPlan.mentor.short_description)+" ")])],1)],1),_c('b-col',{staticClass:"pt-5 pb-6",attrs:{"cols":"auto"}},[(_vm.mentoringPlan.mentor.all_social_links)?_c('b-row',{attrs:{"align-h":"center","no-gutters":""}},_vm._l((Object.keys(
                _vm.mentoringPlan.mentor.all_social_links
              )),function(link,key){return _c('b-button',{key:key,staticClass:"mx-3 social-link",attrs:{"href":_vm.mentoringPlan.mentor.all_social_links[link],"variant":"outline-primary"}},[_c('inline-svg',{attrs:{"src":require(`@/assets/icons/${link}.svg`)}})],1)}),1):_vm._e()],1)],1)],1),_c('b-col',{staticClass:"mb-6 mb-md-0",attrs:{"cols":"12","md":"","order":"1","order-md":"2"}},[_c('h1',{staticClass:"font-weight-medium text-color-2 mb-3"},[_vm._v(" "+_vm._s(_vm.$t("mentroingprogram.menteeMentoringProgramHeading"))+" ")]),_c('div',{staticClass:"position-relative"},[_c('span',{staticClass:"font-weight-medium font-size-16 w-100 text-center"},[_vm._v(_vm._s(_vm.mentoringPlan.title))]),_c('b-img',{staticClass:"position d-block mx-auto",attrs:{"src":require(`@/assets/icons/${
              _vm.statuses[_vm.mentoringPlan.status].icon
            }.svg`),"height":"155"}}),_c('span',{staticClass:"position-absolute bottom-0 left-0 w-100 text-center",style:({ color: _vm.statuses[_vm.mentoringPlan.status].color })},[_vm._v(_vm._s(_vm.statuses[_vm.mentoringPlan.status].label))])],1)]),_c('b-col',{staticClass:"pl-2 pl-sm-6 pl-md-0 pl-lg-9 pr-5 pr-sm-6",attrs:{"cols":"6","md":"3","order":"3"}},[_c('b-row',{staticClass:"flex-column h-100",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-inline-block position-relative rounded-pill border border-2 border-primary"},[_c('router-link',{attrs:{"to":_vm.profileLink(_vm.mentoringPlan.mentee)}},[_c('b-avatar',{staticClass:"border-white main-avatar",attrs:{"src":_vm.mentoringPlan.mentee && _vm.mentoringPlan.mentee.profile_link,"size":"7.15rem"}}),_c('span',{staticClass:"active-indicator"})],1)],1)]),_c('b-col',{staticClass:"mt-3"},[_c('b-row',{staticClass:"bg-white border-radius-7 py-1 px-1",attrs:{"align-h":"center","no-gutters":""}},[_c('b-col',{staticClass:"font-size-18 font-weight-semi-bold",attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":_vm.profileLink(_vm.mentoringPlan.mentee)}},[_c('p',{staticClass:"text-color-2 mentoring-session-name-font"},[_vm._v(" "+_vm._s(_vm.mentoringPlan.mentee.name)+" ")])])],1),_c('b-col',{staticClass:"font-size-12",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.mentoringPlan.mentee.short_description)+" ")])],1)],1),_c('b-col',{staticClass:"pt-5 pb-6",attrs:{"cols":"auto"}},[(_vm.mentoringPlan.mentee.all_social_links)?_c('b-row',{attrs:{"align-h":"center","no-gutters":""}},_vm._l((Object.keys(
                _vm.mentoringPlan.mentee.all_social_links
              )),function(link){return _c('b-button',{key:link,staticClass:"mx-3 social-link",attrs:{"href":_vm.mentoringPlan.mentee.all_social_links[link],"variant":"outline-primary"}},[_c('inline-svg',{attrs:{"src":require(`@/assets/icons/${link}.svg`)}})],1)}),1):_vm._e()],1)],1)],1)],1),_c('b-row',{staticClass:"bg-white buttons-panel py-7",attrs:{"align-h":"between","align-v":"center","no-gutters":""}},_vm._l((_vm.infoData),function(dataItem){return _c('b-col',{key:dataItem.name,staticClass:"py-2 py-lg-0 px-sm-5",attrs:{"cols":"12","lg":"auto","md":"4","sm":"auto"}},[_c('b-row',{staticClass:"justify-content-sm-center flex-nowrap px-8 px-sm-0",attrs:{"align-v":"center","no-gutters":""}},[_c('b-col',{staticClass:"mr-2",attrs:{"cols":"auto"}},[_c('span',{staticClass:"bg-light-blue rounded-icon"},[_c('inline-svg',{attrs:{"src":require(`@/assets/icons/${dataItem.icon}.svg`),"height":"14","stroke":"currentColor"}})],1)]),_c('b-col',{staticClass:"text-color-4 font-size-12 mr-2",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(dataItem.label)+" ")]),_c('b-col',{staticClass:"text-color-1 font-weight-semi-bold",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(dataItem.value)+" ")])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }